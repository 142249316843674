<template>
  <div class="">
    <div>
      <!-- 品項明細 -->
      <div class="item-detail-container">
        <div v-for="item in displayCartItemInfo" :key="item.nanoId" @click="onExpand(item.nanoId)">
          <div class="item-row">
            <p>{{ item.name }} x{{ item.quantity }}</p>
            <p class="flex-shrink-0">$ {{ item.totalPrice }}</p>
          </div>
          <el-collapse-transition>
            <div
              v-if="expand.item[item.nanoId] && item.discountsDetail.length"
              class="item-discount-detail"
              style="padding: 0 8px"
            >
              <div v-for="discount in item.discountsDetail" :key="discount.nanoId">
                <div class="row" style="padding: 4px 0;">
                  <p>{{ discount.name }}</p>
                  <p class="flex-shrink-0">-$ {{ discount.savePrice }}</p>
                </div>
              </div>
            </div>
          </el-collapse-transition>
        </div>
      </div>

      <el-divider style="margin: 12px 0;" />

      <div class="flex flex-col" style="gap: 12px; padding: 0 8px;">
        <!-- 整單折扣 -->
        <div>
          <div class="row" @click="expand.orderDiscount = !expand.orderDiscount">
            <p>整單折扣</p>
            <p class="flex-shrink-0">$ {{ displayData.orderDiscountPrice }}</p>
          </div>
          <el-collapse-transition>
            <div v-if="expand.orderDiscount" class="item-discount-detail">
              <div v-for="discount in displayData.orderDiscount" :key="discount.id">
                <div class="row">
                  <p>{{ discount.name }}</p>
                  <p class="flex-shrink-0">-{{ discount.savePrice }}</p>
                </div>
              </div>
            </div>
          </el-collapse-transition>
        </div>
      </div>

      <!-- 本次新增 -->
      <div v-if="showControl.addBenefits">
        <el-divider style="margin: 12px 0;" />
        <div class="flex flex-col" style="gap: 8px; padding: 0 8px;">
          <p class="text-gray-100 font-medium">本次新增</p>
          <div v-for="item in displayAddBenefits" :key="item.id" class="row">
            <p class="font-normal">{{ item.label }}</p>
            <p class="font-normal flex-shrink-0">{{ item.prefix }} {{ item.amount }} {{ item.suffix }}</p>
          </div>
        </div>
      </div>

      <!-- 本次扣除 -->
      <div v-if="showControl.usedBenefits">
        <el-divider style="margin: 12px 0;" />
        <div class="flex flex-col" style="gap: 8px; padding: 0 8px;">
          <p class="text-gray-100 font-medium">本次扣除</p>
          <div v-for="item in displayUsedBenefits" :key="item.id" class="row">
            <p class="font-normal">{{ item.label }}</p>
            <p class="font-normal flex-shrink-0">{{ item.prefix }} {{ item.amount }} {{ item.suffix }}</p>
          </div>
        </div>
      </div>

      <el-divider style="margin: 12px 0;" />
      <div class="flex flex-col" style="gap: 12px; padding: 0 8px;">
        <!-- 總折扣 -->
        <div class="row">
          <p>總折扣</p>
          <p class="text-danger flex-shrink-0">-$ {{ displayData.totalDiscountPrice }}</p>
        </div>
      </div>

      <el-divider style="margin: 12px 0;" />

      <div class="row" style="padding: 0 8px;">
        <p class="font-bold text-normal">合計</p>
        <p class="font-medium text-sub flex-shrink-0">$ {{ displayData.orderUnpaidPrice }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useShop } from '@/use/shop'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { isEmpty, uniqueId, filter, forEach, find, get, map } from 'lodash'

import { computed, defineComponent, onBeforeMount, reactive, ref, set } from 'vue'
export default defineComponent({
  name: 'SalesPOSCheckoutReceipt',
  setup (props) {
    const {
      context, cart, orderUnpaidPrice,
      getAllOrderPaymentsPrice,
      getAllSingleDiscountsPrice,
      getAllOrderDiscountsPrice,
      getAllOrderDiscountsDetail,
      configData,
      computedUsedResource,
    } = useSalesPOSCreate()
    const { defaultShopPoint } = useShop()
    const expand = reactive({
      item: {},
      orderDiscount: true,
    })

    const displayCartItemInfo = computed(() => {
      return map(cart.value, (item) => {
        return {
          nanoId: item.nanoId,
          name: item.name,
          totalPrice: item.totalPrice(),
          discountsDetail: item.getSingleDiscountsDetail(),
          quantity: item.quantity,
        }
      })
    })

    const displayData = computed(() => {
      return {
        orderUnpaidPrice: orderUnpaidPrice.value,
        paymentsPrice: getAllOrderPaymentsPrice(),
        totalDiscountPrice: getAllSingleDiscountsPrice() + getAllOrderDiscountsPrice(),
        orderDiscount: getAllOrderDiscountsDetail(),
        orderDiscountPrice: getAllOrderDiscountsPrice(),
      }
    })

    const displayAddBenefits = computed(() => {
      const result = []

      const classTicketProducts = filter(cart.value, { useClassTicket: true })
      const walletProducts = filter(cart.value, { useCoin: true })

      forEach(classTicketProducts, (item) => {
        if (item) {
          result.push({
            id: uniqueId(),
            label: item.name,
            amount: item.quantity,
            suffix: '組',
          })
        }
      })

      let totalWallet = 0
      forEach(walletProducts, (item) => {
        if (item) {
          totalWallet += item.coinAmount * item.quantity
        }
      })
      if (totalWallet) {
        result.push({
          id: uniqueId(),
          label: '儲值金',
          amount: totalWallet,
          prefix: '$',
        })
      }

      return result
    })

    const displayUsedBenefits = computed(() => {
      const used = computedUsedResource.value

      const result = []

      if (used.point) {
        result.push({
          id: uniqueId(),
          label: `扣除${get(defaultShopPoint.value, 'name', '點數')}`,
          amount: used.point,
          suffix: get(defaultShopPoint.value, 'unitName', '點'),
        })
      }
      if (used.cashback) {
        result.push({
          id: uniqueId(),
          label: '扣除回饋金',
          amount: used.cashback,
          prefix: '$',
        })
      }
      // 票券
      if (used.coupon.length) {
        forEach(used.coupon, (item) => {
          const couponRecord = find(get(configData, 'consumerData.couponRecordDetails'), {
            CouponRecord: { id: item },
          })
          if (couponRecord) {
            result.push({
              label: `扣除${get(couponRecord, 'CouponRecord.Coupon.name')}`,
              amount: 1,
              suffix: '張',
            })
          }
        })
      }
      // 預約堂票
      if (!isEmpty(used.classTicket)) {
        for (const classTicketRecordId of Object.keys(used.classTicket)) {
          console.log('classTicketRecordId 1', classTicketRecordId)
          const classTicketRecord = find(get(configData, 'consumerData.classTicketRecords'), { id: classTicketRecordId })
          console.log('here', classTicketRecord)
          if (classTicketRecord) {
            result.push({
              label: `扣除${classTicketRecord.name}`,
              amount: used.classTicket[classTicketRecordId],
              suffix: '張',
            })
          }
        }
      }
      // 堂票紀錄
      if (!isEmpty(used.classTicketRecord)) {
        for (const classTicketRecordId of Object.keys(used.classTicketRecord)) {
          const classTicketRecord = find(get(configData, 'consumerData.classTicketRecords'), { id: classTicketRecordId })
          if (classTicketRecord) {
            result.push({
              label: `扣除${classTicketRecord.name}`,
              amount: used.classTicketRecord[classTicketRecordId],
              suffix: '張',
            })
          }
        }
      }

      return result
    })

    const showControl = computed(() => {
      const controls = {
        addBenefits: false,
        usedBenefits: false,
      }

      if (displayAddBenefits.value.length) {
        controls.addBenefits = true
      }
      if (displayUsedBenefits.value.length) {
        controls.usedBenefits = true
      }

      return controls
    })

    const initExpand = () => {
      for (const item of cart.value) {
        set(expand.item, item.nanoId, true)
      }
    }

    const onExpand = (nanoId) => {
      expand.item[nanoId] = !expand.item[nanoId]
    }

    onBeforeMount(() => {
      initExpand()
    })

    return { showControl, displayData, displayCartItemInfo, expand, onExpand, displayAddBenefits, displayUsedBenefits }
  },
})
</script>

<style lang="postcss" scoped>
.row {
  @apply flex justify-between gap-[8px];
  @apply font-medium;
}

.item-row {
  @apply flex justify-between bg-gray-20 gap-[8px];
  @apply py-[4px] px-[8px] rounded-[6px];
  @apply font-medium;
}

.item-discount-detail {
  @apply gap-[6px] pt-[6px];
  @apply flex flex-col;
  @apply text-gray-60 text-sm;
}

::v-deep(.el-divider) {
  @apply my-[12px];
}

.item-detail-container {
  @apply flex flex-col gap-[6px];
  /* @apply max-h-[350px] overflow-y-auto; */
}
</style>
