<template>
  <div class="sales-pos-checkout-consumer-info">
    <div class="flex flex-col" style="gap: 6px">
      <p>{{ displayData.name }}</p>
      <div class="flex" style="gap: 6px">
        <p>{{ displayData.phone }}</p>
        <p>{{ displayData.levelName }}</p>
      </div>
    </div>

    <div class="flex" style="gap: 12px">
      <div class="grid grid-cols-2" style="column-gap: 12px; row-gap: 6px;">
        <div v-for="resource in displayResourceFields" :key="resource.label" class="text-sub">
          <span class="font-medium">{{ resource.label }}</span>：<span class="text-primary-100">{{ resource.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { usePermissions } from '@/use/permissions'
import { useShop } from '@/use/shop'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { isNull, filter, get } from 'lodash'

import { computed, defineComponent } from 'vue'
export default defineComponent({
  name: 'SalesPOSCheckoutConsumerInfo',
  setup (props, { emit }) {
    const { context, configData } = useSalesPOSCreate()
    const { checkAction } = usePermissions()
    const { defaultShopPoint } = useShop()

    const useFeatures = computed(() => {
      return {
        usePoint: checkAction('admin.shopPoint.page'),
        useChashback: checkAction('admin.shopCashback.page'),
        useClassTicket: checkAction('admin.classTicket.page'),
        useCoupon: checkAction('admin.coupon.page'),
      }
    })

    const displayText = computed(() => {
      const text = {
        pointName: get(defaultShopPoint.value, 'name', '點數'),
      }
      return text
    })

    const displayData = computed(() => {
      const consumerData = configData.consumerData
      let userName
      let userPhone
      let levelName

      if (get(context.member, 'id')) {
        userName = get(consumerData, 'userInfo.name')
        userPhone = get(consumerData, 'userInfo.phone')
        const memberLevel = get(consumerData, 'member.level')
        levelName = get(configData.memberConfig, `levels.${memberLevel}.name`)
      } else {
        userName = get(context.member, 'name')
        userPhone = get(context.member, 'phone')
      }

      return {
        name: userName,
        phone: userPhone,
        levelName,
        resources: {
          cashback: get(consumerData, 'cashbackBalance'), // 回饋金
          wallet: get(consumerData, 'walletBalance'), // 儲值金
          coupon: get(consumerData, 'couponRecords.length'), // 票券
          point: get(consumerData, 'pointBalance'), // 點數
        },
      }
    })

    const displayResourceFields = computed(() => {
      const omitList = []
      const dields = [
        { label: '儲值金', value: displayData.value.resources.wallet },
        { label: displayText.value.pointName, value: displayData.value.resources.point },
        { label: '回饋金', value: displayData.value.resources.cashback },
        { label: '票券', value: displayData.value.resources.coupon },
      ]

      if (isNull(displayData.value.resources.point)) omitList.push(displayText.value.pointName)
      if (isNull(displayData.value.resources.cashback)) omitList.push('回饋金')
      if (isNull(displayData.value.resources.coupon)) omitList.push('票券')
      if (isNull(displayData.value.resources.wallet)) omitList.push('儲值金')

      if (!get(context, 'member.id')) {
        return []
      }

      return filter(dields, (field) => !omitList.includes(field.label))
    })

    return { displayResourceFields, displayData }
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-checkout-consumer-info {
  @apply bg-primary-10 rounded-[6px] p-[12px];
  @apply flex items-center justify-between select-none gap-[12px];
}

</style>
