<template>
  <BaseDialog title="請用刷卡機掃描 QRcode" width="315px" hideConfirm hideCancel :show-close="false">
    <div>
      <div class="flex justify-center justify-center">
        <img :src="qrcode" alt="QR Code" style="width: 179px;">
      </div>

      <div class="flex justify-center" style="margin-bottom: 63px">
        <div v-if="showControl.hint.overdue" class="overdue-hint">
          {{ displayData.hint.overdue }}
        </div>

        <div v-if="showControl.hint.paid" class="text-success">
          {{ displayData.hint.paid }}
        </div>

        <div v-if="showControl.hint.processing" class="text-primary">
          {{ displayData.hint.processing }}
        </div>

        <div v-if="showControl.hint.refund" class="text-danger">
          {{ displayData.hint.refund }}
        </div>

        <div v-if="showControl.hint.fail" class="text-danger">
          {{ displayData.hint.fail }}
        </div>

        <div v-if="showControl.counter" class="flex items-center" style="gap: 6px;">
          <span class="text-sm">{{ displayData.countdownTimeFormat }}</span>
          <MaterialIcon size="16">schedule</MaterialIcon>
        </div>
      </div>

      <div class="flex flex-col" style="gap: 32px">
        <div class="row">
          <p>刷卡金額</p>
          <p>$ {{ displayData.paymentPrice }}</p>
        </div>
        <div class="row">
          <p>建立時間</p>
          <p>{{ displayData.createdAt }}</p>
        </div>
      </div>
    </div>

    <div class="flex justify-center items-center" style="margin-top: 32px;">
      <BaseElButton type="primary" @click="onNextOrder">進行下一筆訂單</BaseElButton>
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { FindSalesRecord } from '@/api/sales'
import { apiFormatAdaptor } from '@/utils/api'
import { useShop } from '@/use/shop'
import { get } from 'lodash'
import { useQRCode } from '@vueuse/integrations/useQRCode'
import { formatDate } from '@/utils/date'

export default defineComponent({
  name: 'SalesPOSCheckoutEzaioBillQrcodeModal',
  components: {
    BaseDialog,
  },
  props: {
    recordId: {
      type: String,
      required: true,
    },
  },
  setup (props, { emit }) {
    const { reset } = useSalesPOSCreate()
    const router = useRouter()
    const { shopId } = useShop()
    const paymentCode = ref(null)
    const orderData = ref(null)
    const timer = reactive({
      counter: null,
      orderCheck: null,
    })
    const loading = ref(false)
    const qrcode = useQRCode(paymentCode)
    // 5 minutes (300 seconds)
    const countdownTime = ref(300)

    const showControl = computed(() => {
      const controls = {
        counter: false,
        hint: {
          overdue: false,
          paid: false,
          processing: false,
          refund: false,
          fail: false,
        },
      }

      const status = get(orderData.value, 'SalesPaymentOrder.status')

      if (timer.counter && countdownTime.value > 0) {
        controls.counter = true
      }

      if (status === 'paid') {
        controls.hint.paid = true
      } else if (status === 'processing') {
        controls.hint.processing = true
      } else if (status === 'refund') {
        controls.hint.refund = true
      } else if (status === 'fail') {
        controls.hint.fail = true
      }

      if (countdownTime.value <= 0) {
        controls.hint.overdue = true
        controls.hint.paid = false
        controls.hint.processing = false
        controls.hint.refund = false
        controls.hint.fail = false
      }

      return controls
    })

    const displayData = computed(() => {
      const createdAt = get(orderData.value, 'SalesPaymentOrder.createdAt')
      return {
        // mm:ss
        countdownTimeFormat: `${Math.floor(countdownTime.value / 60).toString().padStart(2, '0')}:${(countdownTime.value % 60).toString().padStart(2, '0')}`,
        paymentPrice: get(orderData.value, 'SalesPaymentOrder.amount'),
        createdAt: createdAt ? formatDate(createdAt, 'YYYY/MM/DD HH:mm') : '-',

        hint: {
          overdue: '交易逾時 該筆訂單已取消',
          paid: '該筆訂單已付款',
          processing: '該筆訂單付款中',
          refund: '該筆訂單已退款',
          fail: '該筆訂單付款失敗',
        },
      }
    })

    const startCountdown = () => {
      timer.counter = setInterval(() => {
        if (countdownTime.value <= 0) {
          clearInterval(timer.counter)
          timer.counter = null
          emit('countdownFinished')
        } else {
          countdownTime.value -= 1
          emit('updateCountdown', countdownTime.value)
        }
      }, 1000)
    }

    const checkOrderStatus = () => {
      // 每 3 秒輪詢一次，直到 status 不是 pending 為止
      timer.orderCheck = setInterval(async () => {
        const [res, err] = await apiFormatAdaptor(FindSalesRecord)({
          shopId: shopId.value,
          id: props.recordId,
        })

        if (err) {
          window.$message.error(err)
          clearInterval(timer.orderCheck)
          return
        }
        const paymentStatus = get(res, 'SalesPaymentOrder.status')
        if (paymentStatus !== 'pending') {
          clearInterval(timer.orderCheck)
          if (paymentStatus === 'paid') {
            window.$message.success('付款成功')
            setTimeout(() => {
              router.push({ name: 'SalesPOSEntry', query: { new: true } })
            }, 1000)
          }
        }
      }, 3000)
    }

    const onNextOrder = () => {
      reset()
      if (timer.counter) clearInterval(timer.counter)
      if (timer.orderCheck) clearInterval(timer.orderCheck)
      router.push({ name: 'SalesPOSEntry', query: { new: true } })
    }

    onBeforeMount(async () => {
      loading.value = true
      const [res, err] = await apiFormatAdaptor(FindSalesRecord)({
        shopId: shopId.value,
        id: props.recordId,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }

      orderData.value = res
      paymentCode.value = get(res, 'SalesPaymentOrder.code')
      const paymentStatus = get(orderData.value, 'SalesPaymentOrder.status')
      if (paymentStatus === 'pending') {
        startCountdown()
        checkOrderStatus()
      }
    })

    onBeforeUnmount(() => {
      if (timer.counter) clearInterval(timer.counter)
      if (timer.orderCheck) clearInterval(timer.orderCheck)
    })

    return {
      qrcode,
      displayData,
      showControl,
      onNextOrder,
      countdownTime,
    }
  },
})
</script>

<style scoped lang="postcss">
.row {
  @apply flex justify-between items-center;
}

.overdue-hint {
  @apply text-danger text-center;
}
</style>
