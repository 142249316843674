<template>
  <div class="sales-pos-checkout">
    <div class="left-panel">
      <div class="flex items-center justify-between">
        <div class="edit-order-btn " @click="onEditOrder">
          <MaterialIcon>chevron_left</MaterialIcon>
          <p>修改明細</p>
        </div>
        <div class="operate-btn" @click="curTab = 'orderDiscount'">
          整單折扣
        </div>
      </div>
      <SalesPOSCheckoutConsumerInfo />

      <SalesPOSCheckoutDetail
        @refreshRewardInfo="salesPosPreCreateReward"
      />
    </div>
    <div class="right-panel">
      <p class="tab-title">{{ displayData.rightPanel.title }}</p>
      <div class="panel-content-wrapper">
        <SalesPOSCheckoutMethods
          v-show="curTab === 'checkout'"
          class="h-full"
        />
        <SalesPOSOrderDiscountMethods
          v-show="curTab === 'orderDiscount'"
          class="h-full"
        />
      </div>

      <div>
        <BaseElButton v-if="curTab === 'checkout'" class="checkout-btn" type="primary" @click="onCheckout">
          出帳明細
        </BaseElButton>
        <BaseElButton v-if="curTab === 'orderDiscount'" class="checkout-btn" type="success" @click="curTab = 'checkout'">
          返回結帳
        </BaseElButton>
      </div>
    </div>

    <SalesPOSCheckoutConfirmModal
      v-if="modal.receipt"
      @close="modal.receipt = false"
    />
    <SalesPOSCheckoutEzaioReceiptModal
      v-if="modal.ezaioReceipt"
      @close="modal.ezaioReceipt = false"
      @generateQRcode="onGenerateEZAioQRcode"
    />
    <SalesPOSCheckoutEzaioBillQrcodeModal
      v-if="modal.billQRCode"
      :recordId="lastCreateRecordId"
      @close="modal.billQRCode = false"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, onMounted, provide, reactive, ref, watch } from 'vue'
import SalesPOSCheckoutMethods from './components/SalesPOSCheckoutMethods.vue'
import SalesPOSCheckoutConsumerInfo from './components/SalesPOSCheckoutConsumerInfo.vue'
import SalesPOSOrderDiscountMethods from './components/SalesPOSOrderDiscountMethods.vue'
import SalesPOSCheckoutEzaioReceiptModal from './components/SalesPOSCheckoutEzaioReceiptModal.vue'
import SalesPOSCheckoutDetail from './components/SalesPOSCheckoutDetail.vue'
import { SalesPosPreCreateReward } from '@/api/salesPOS'
import { useShop } from '@/use/shop'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { get, map, find, filter, reduce } from 'lodash'
import SalesPOSCheckoutEzaioBillQrcodeModal from './components/SalesPOSCheckoutEzaioBillQrcodeModal.vue'
import SalesPOSCheckoutConfirmModal from './components/SalesPOSCheckoutConfirmModal.vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router/composables'
import { useWindowUnload } from '@/use/useWindowUnload'
import store from '@/store'

export default defineComponent({
  name: 'SalesPOSCheckout',
  components: {
    SalesPOSCheckoutConfirmModal,
    SalesPOSCheckoutDetail,
    SalesPOSCheckoutMethods,
    SalesPOSCheckoutConsumerInfo,
    SalesPOSOrderDiscountMethods,
    SalesPOSCheckoutEzaioReceiptModal,
    SalesPOSCheckoutEzaioBillQrcodeModal,
  },
  setup (props) {
    const { shopId } = useShop()
    const router = useRouter()
    const {
      context, cart, getAllSingleDiscountsPrice, getAllOrderDiscountsDetail, formatOrderData,
      computedUsedResource,
      reset, originData, flags,
    } = useSalesPOSCreate()
    const { registerWarnUnloadEvent } = useWindowUnload()
    registerWarnUnloadEvent()
    const routeBypass = ref(false)
    const curTab = ref('checkout')
    const rewardInfo = ref(null)
    const lastCreateRecordId = ref(null)
    const modal = reactive({
      receipt: false,
      billQRCode: false,
      ezaioReceipt: false,
    })

    const loading = reactive({
      rewardInfo: false,
    })

    const displayData = computed(() => {
      const data = {
        rightPanel: {
          title: '選擇結帳方式',
        },
      }

      if (curTab.value === 'orderDiscount') {
        data.rightPanel.title = '整單折扣'
      }

      return data
    })

    const formatCheckoutDiscounts = () => {
      return [
        {
          name: '單項折扣總和',
          savePrice: getAllSingleDiscountsPrice(),
          type: 'totalItemDiscount',
        },
        ...getAllOrderDiscountsDetail(),
      ]
    }

    const salesPosPreCreateReward = async () => {
      if (!get(context.member, 'id')) return
      loading.rewardInfo = true
      let setSalesCashbackAmount = context.oldCashbackUse
      if (setSalesCashbackAmount === '0') setSalesCashbackAmount = 0
      if (setSalesCashbackAmount === '') setSalesCashbackAmount = null
      console.log('flags.oldCashbackUseChanged', flags.oldCashbackUseChanged)
      if (!flags.oldCashbackUseChanged) {
        setSalesCashbackAmount = null
      }

      const [res, err] = await SalesPosPreCreateReward({
        shopId: shopId.value,
        memberId: context.member.id,
        salesRecordItems: map(cart.value, (item) => item.formatItemData()),
        salesRecordDiscounts: formatCheckoutDiscounts(),
        setSalesCashbackAmount,
      })
      loading.rewardInfo = false
      if (err) {
        window.$message.error(err)
        return
      }

      const oldCashbackRewardList = filter(get(res, 'rewards'), { type: 'salesCashback' }) // 舊制回饋金
      context.oldCashbackUse = oldCashbackRewardList.length ? reduce(oldCashbackRewardList, (sum, item) => sum + get(item, 'salesCashbackInfo.amount', 0), 0) : null

      // 設置第一次的回饋金，用來當作是否手動變動回饋金的依據
      if (!originData.oldCashbackUse) {
        originData.oldCashbackUse = oldCashbackRewardList.length ? reduce(oldCashbackRewardList, (sum, item) => sum + get(item, 'salesCashbackInfo.amount', 0), 0) : null
      }
      rewardInfo.value = get(res, 'rewards')
    }

    const orderPaymentLength = computed(() => {
      return context.payments.length
    })
    const orderDiscountLength = computed(() => {
      return context.orderDiscount.length
    })

    const onCheckout = () => {
      const isEzaio = find(context.payments, { type: 'ezAIOTrust' })
      if (isEzaio) {
        modal.ezaioReceipt = true
      } else {
        modal.receipt = true
      }
    }

    const onGenerateEZAioQRcode = (recordId) => {
      console.log('recordId', recordId)
      lastCreateRecordId.value = recordId
      modal.ezaioReceipt = false
      modal.billQRCode = true
    }

    const onEditOrder = () => {
      context.payments = filter(context.payments, (payment) => payment.type === 'paidDeposit')
      context.orderDiscount = []
      originData.oldCashbackUse = null
      flags.oldCashbackUseChanged = false
      router.push({ name: 'SalesPOS', query: { edit: true } })
    }

    watch(orderPaymentLength, () => {
      console.log('watch payments')
      salesPosPreCreateReward()
    })

    watch(orderDiscountLength, () => {
      console.log('watch orderDiscount')
      salesPosPreCreateReward()
    })

    provide('rewardInfo', rewardInfo)
    provide('loading', loading)

    onBeforeMount(() => {
      store.commit('app/SET_SIDEBAR', false)
      if (!cart.value.length) {
        routeBypass.value = true
        router.push({
          name: 'SalesPOS',
        })
      }
    })

    onMounted(() => {
      salesPosPreCreateReward()
    })

    onBeforeRouteLeave((to, from, next) => {
      if (routeBypass.value) return next()
      const routeQuery = to.query
      if ((to.name === 'SalesPOS' && routeQuery.edit) || (to.name === 'SalesPOSEntry' && routeQuery.new)) {
        return next()
      } else if (to.name === 'SalesPOS' && cart.value.length) {
        const confirm = window.confirm('將會清除此工作階段內容，確定要離開嗎？')
        if (confirm) {
          context.payments = filter(context.payments, (payment) => payment.type === 'paidDeposit')
          context.orderDiscount = []
        } else {
          store.commit('app/TOGGLE_PAGE_CHANGING', false)
          return next(false)
        }
      } else {
        const confirm = window.confirm('將會清除此工作階段內容，確定要離開嗎？')
        if (confirm) {
          reset()
        } else {
          store.commit('app/TOGGLE_PAGE_CHANGING', false)
          return next(false)
        }
      }

      next()
    })

    return {
      modal,
      curTab,
      cart,
      context,
      salesPosPreCreateReward,
      formatOrderData,
      onCheckout,
      onGenerateEZAioQRcode,
      lastCreateRecordId,
      displayData,
      computedUsedResource,
      originData,
      onEditOrder,
    }
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-checkout {
  @apply flex bg-white h-full overflow-hidden;
}

.tab-title {
  @apply text-center text-primary-100 font-bold text-[18px] leading-[35px] select-none;
}

.left-panel,
.right-panel {
  @apply w-full;
}

.left-panel {
  @apply flex flex-col gap-[12px] p-[12px];
}

.right-panel {
  @apply bg-[#F6F7FB];
  @apply py-[12px] px-[16px];
  @apply flex flex-col gap-[12px];
  @apply h-full;
}

.checkout-btn {
  @apply w-full h-[118px] text-[24px];
}

.panel-content-wrapper {
  @apply rounded-[6px];
  @apply flex-1 overflow-hidden;
}

.operate-btn {
  @apply bg-primary-30 text-primary-100 font-medium text-normal w-[fit-content];
  @apply py-[6px] px-[12px] rounded-[6px];
  @apply select-none cursor-pointer;
}

.edit-order-btn {
  @apply flex items-center text-sub text-primary-100 font-bold cursor-pointer select-none gap-[6px] w-[fit-content];
}
</style>
